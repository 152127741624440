<!--
 * @Author: frank
 * @Description: 
 * @Date: 2021-01-18 09:19:52
 * @LastEditTime: 2021-05-31 09:06:28
 * @FilePath: /shop_frontend/src/views/orderList/allOrderType.vue
-->
<template>
  <div>
    <div class="formBox">
      <a-form :form="form">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="订单号">
              <a-input
                placeholder="搜索商品"
                style="width: 220px"
                allowClear
                v-model="number"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人姓名">
              <a-input
                placeholder="收货人姓名"
                style="width: 220px"
                v-model="search"
                allowClear
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人手机号">
              <a-input
                placeholder="收货人手机号"
                style="width: 220px"
                allowClear
                v-model="client_phone"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="买家昵称">
              <a-input
                placeholder="买家昵称"
                style="width: 220px"
                v-model="member_name"
                allowClear
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="6">
            <a-form-item>
              <a-button
                type="primary"
                @click="handleSearch"
                style="margin-right: 10px"
              >
                筛选
              </a-button>
              <a-button type="" @click="exportExcel" style="margin-right: 10px">
                导出
              </a-button>
              <a-button type="primary" @click="printAllOrder()"
                >一键打单</a-button
              >
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <a-tabs :default-active-key="-1" @change="onChangeTab">
        <a-tab-pane :key="-1" tab="全部"> </a-tab-pane>
        <a-tab-pane :key="el.value" :tab="el.name" v-for="el in statusList">
        </a-tab-pane>
      </a-tabs>
    </div>
    <div class="page__table">
      <table class="custom__table">
        <colgroup>
          <col width="20%" />
          <col width="20%" />
          <col width="5%" />
          <col width="5%" />
          <col width="10%" />
          <col width="10%" />
          <!-- <col width="10%" /> -->
          <col width="10%" />
          <col width="10%" />
        </colgroup>
        <tr style="background: #f5f5f5; font-weight: 700">
          <td>商品</td>
          <td>货号/规格编码</td>
          <td>单价</td>
          <td>数量</td>
          <td>下单人</td>
          <td>收货人</td>
          <!-- <td>下单时间</td> -->
          <td>订单状态</td>
          <td>支付金额</td>
        </tr>
      </table>

      <table
        class="custom__table"
        v-for="item in dataSource"
        :key="'order' + item.id"
        style="margin: 20px 0"
      >
        <colgroup>
          <col width="20%" />
          <col width="20%" />
          <col width="5%" />
          <col width="5%" />
          <col width="10%" />
          <col width="10%" />
          <!-- <col width="10%" /> -->
          <col width="10%" />
          <col width="10%" />
        </colgroup>
        <tr style="background: #f5f5f5">
          <td colspan="9">
            <div class="flex custom__table_order">
              <span>订单号：{{ item.number }}</span>
              <span>下单时间：{{ item.payment_time }}</span>
              <span
                >配送方式：{{
                  item.delivery_mode == "0" ? "快递" : "自提"
                }}</span
              >
              <span class="btns">
                <span @click="openRemark(item)">添加备注</span>
                <span @click="openDetail(item)">查看详情</span>
                <span @click="$router.push({ name: 'goodsEvaluate' })"
                  >查看评论</span
                >
              </span>
            </div>
          </td>
        </tr>
        <tr v-for="(el, cIndex) in item.sales_goods_items" :key="el.id">
          <td>
            <div class="flex">
              <img
                :src="el.spec_picture_url"
                alt=""
                style="
                  display: inline-block;
                  width: 80px;
                  height: 80px;
                  margin-right: 10px;
                "
              />
              <p style="display: inline-block; max-width: 140px">
                {{ el.goods_name }}
              </p>
            </div>
          </td>
          <td>{{ el.spec_name }}</td>
          <td>{{ el.member_price }}</td>
          <td>{{ el.quantity }}</td>
          <td :rowspan="item.sales_goods_items.length" v-if="cIndex === 0">
            <span
              style="
                cursor: pointer;
                color: #1890ff;
                text-decoration: underline;
              "
              @click="toMember(item)"
              >{{ item.member_name }}</span
            >
          </td>
          <td :rowspan="item.sales_goods_items.length" v-if="cIndex === 0">
            {{ item.client_name }}
          </td>
          <!-- <td :rowspan="item.sales_goods_items.length" v-if="cIndex === 0">
            {{ item.payment_time }}
          </td> -->
          <td :rowspan="item.sales_goods_items.length" v-if="cIndex === 0">
            <span>{{ item.status | statusShow }}</span>
            <div>
              <a-button
                type="primary"
                size="small"
                @click="sendGoods(item)"
                v-if="item.status == 1"
                >发货</a-button
              >
            </div>
            <div style="margin-top: 4px">
              <a-button
                type="primary"
                v-if="item.status == 1"
                size="small"
                @click="printOrder(item)"
                >打单</a-button
              >
            </div>
            <div style="margin-top: 4px">
              <a-button
                type="primary"
                v-if="item.status == 2"
                size="small"
                @click="printOrder(item)"
                >继续打单</a-button
              >
            </div>
            <div style="margin-top: 4px">
              <a-button
                type="primary"
                size="small"
                @click="allPrintOrder(item)"
                >重复打单</a-button
              >
            </div>
          </td>
          <td :rowspan="item.sales_goods_items.length" v-if="cIndex === 0">
            {{ item.payment_amount }}
          </td>
        </tr>
      </table>
    </div>
    <div style="text-align: right; margin-top: 20px">
      <a-pagination v-bind="pagination" @change="changePage" />
    </div>

    <a-modal
      title="添加备注"
      :visible="visible"
      @ok="handleOk"
      @cancel="visible = false"
    >
      <p>
        <a-textarea placeholder="备注" v-model="remark" :rows="6" />
      </p>
    </a-modal>
    <a-modal
      title="填入快递信息发货"
      :visible="infoVisible"
      @ok="handleOkInfo"
      @cancel="infoVisible = false"
      width="70%"
    >
      <div>
        <div>
          <div style="margin-bottom: 20px; display: flex">
            <div>
              <span>物流公司：</span>
              <span>
                <a-select style="width: 220px" v-model="logistics_company">
                  <a-select-option
                    :value="el.id"
                    v-for="el in logisticList"
                    :key="el.id"
                  >
                    {{ el.name }}
                  </a-select-option>
                </a-select>
              </span>
            </div>
            <div style="margin-left: 20px">
              <span>物流编号：</span>
              <span>
                <a-input v-model="sendNumber" class="w220"></a-input>
              </span>
            </div>
          </div>
        </div>
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :columns="columns"
          :data-source="sendData"
          :pagination="false"
        >
          <div slot="sendQuantity" slot-scope="text, record">
            <a-input
              v-model="record.sendQuantity"
              class="w220"
              type="number"
            ></a-input>
          </div>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getLodop } from "@/utils/LodopFuncs.js";
import qs from "qs";
const columns = [
  {
    title: "商品名称",
    dataIndex: "goods_name",
  },
  {
    title: "规格",
    dataIndex: "spec_name",
  },
  {
    title: "单价",
    dataIndex: "price",
  },
  {
    title: "数量",
    dataIndex: "quantity",
  },
  {
    title: "发货数量",
    dataIndex: "sendQuantity",
    scopedSlots: { customRender: "sendQuantity" },
  },
];

export default {
  computed: {
    params() {

      let data = {};

      if (this.$route.query.info) {
        let info = JSON.parse(this.$route.query.info);
        data.member_openid = info.openid;
      }
      return {
        search: this.search,
        number: this.number,
        status: this.status,
        member_name: this.member_name,
        client_name: this.client_name,
        client_phone: this.client_phone,
        deliver_mode: this.deliver_mode,
        start_date: this.searchDate[0] || "",
        end_date: this.searchDate[1] || "",
        page: this.page,
        page_size: 15,
        ...data
      };
    },
  },
  props: {
    propStatus: {
      type: Number,
    },
    deliverMode: {
      type: Number,
    },
  },
  data() {
    return {
      logistics_company: "",
      logisticList: [],
      page: 1,
      htmlList: [],
      sendNumber: "",
      sendData: [],
      columns,
      selectedRowKeys: [],
      selectedRows: [],
      currentSendGoods: {},
      infoVisible: false,
      remark: "",
      visible: false,
      current: {},
      search: "",
      number: "",
      status: "",
      member_name: "",
      client_name: "",
      client_phone: "",
      deliver_mode: "",
      searchDate: [],
      deliverList: [
        {
          name: "快递",
          value: 0,
        },
        {
          name: "自提",
          value: 1,
        },
      ],
      statusList: [
        {
          value: 0,
          name: "待付款",
        },
        {
          value: 1,
          name: "待发货",
        },
        {
          value: 2,
          name: "已发货",
        },
        {
          value: 3,
          name: "已完成",
        },
        {
          value: 4,
          name: "售后中",
        },
        {
          value: 5,
          name: "售后完成",
        },
        {
          value: 6,
          name: "已关闭",
        },
      ],
      dataSource: [],
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
      page: 1,
      form: this.$form.createForm(this, { name: "advanced_search" }),
    };
  },
  watch: {
    propStatus: {
      handler: function (nVal) {
        this.status = nVal;
      },
      immediate: true,
    },
    deliverMode: {
      handler: function (nVal) {
        this.deliver_mode = nVal;
      },
      immediate: true,
    },
  },
  created() {

    this.initTable();

    this.getLogisticsCompany();
  },
  methods: {
    getLogisticsCompany() {
      this.$axios.get(`/logistics_companies/`).then((res) => {
        console.log(res);
        this.logisticList = res;
      });
    },
    changePage(page) {
      this.page = page;
      this.initTable();
    },
    toMember(obj) {
      console.log(obj, "----toMember");
      this.$axios.get(`/members/${obj.member}/`).then((res) => {
        this.$router.push({
          name: "memberDetail",
          query: {
            info: JSON.stringify(res),
          },
        });
      });
    },
    convertRes2Blob(response) {
      // 提取文件名
      // const fileName = response.headers['content-disposition'].match(
      //   /filename=(.*)/
      // )[1]
      const filename = "销售订单.xls";
      // 将二进制流转为blob
      const blob = new Blob([response.data], {
        // type: "application/octet-stream",
        type: "application/force-download",
      });
      if (typeof window.navigator.msSaveBlob !== "undefined") {
        // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
        window.navigator.msSaveBlob(blob, decodeURI(filename));
      } else {
        // 创建新的URL并指向File对象或者Blob对象的地址
        const blobURL = window.URL.createObjectURL(blob);
        // 创建a标签，用于跳转至下载链接
        const tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute("download", decodeURI(filename));
        // 兼容：某些浏览器不支持HTML5的download属性
        if (typeof tempLink.download === "undefined") {
          tempLink.setAttribute("target", "_blank");
        }
        // 挂载a标签
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        // 释放blob URL地址
        window.URL.revokeObjectURL(blobURL);
      }
    },
    exportExcel() {
      let params = qs.stringify(this.params);
      console.log(params);
      let excelUrl =
        window.location.origin + "/api/sales_orders/export/?" + params;
      window.location.href = excelUrl;
      // this.$axios
      //   .get("/sales_orders/export/",  {  params: this.params,responseType: 'blob', headers: { 'Content-Type': 'application/vnd.ms-excel' }})
      //   .then((res) => {
      //     console.log(res, 'exportExcel', typeof res);
      //     this.convertRes2Blob(res);
      //   });
    },
    allPrintOrder (obj) {
      // 重复打单

      this.$axios
        .get(`/sales_orders/${obj.id}/repeat_orders/`)
        .then((res) => {
          this.htmlList = res;
          this.printOrderApi(res);
        });
      
    },
    printOrder(obj) {
      this.$axios
        .get(`/sales_orders/${obj.id}/logistics_orders/`)
        .then((res) => {
          console.log(res);
          this.htmlList = res;
          this.printOrderApi(res);
        });
    },
    printAllOrder() {
      let ids = [];
      this.dataSource.forEach((el) => {
        if (el.status == 1) {
          ids.push(el.id);
        }
      });
      console.log(ids);
      if (ids.length === 0) {
        this.$message.warn("当前页无可打单的订单");
        return;
      }
      this.$axios
        .post(`/sales_orders/logistics_orders/`, { ids: ids })
        .then((res) => {
          console.log(res);
          this.htmlList = res;
          this.printOrderApi(res);
        });
    },
    printOrderApi(htmlList) {
      let LODOP = getLodop();
      LODOP.PRINT_INIT("Test"); // 初始化
      LODOP.SET_PRINT_PAGESIZE(1, "76mm", "130mm"); // 设置纸张大小
      LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);
      LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);
      for (let htmlText of htmlList) {
        LODOP.NewPage();
        LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", htmlText);
      }

      LODOP.PRINT();
      this.initTable();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log("selectedRowKeys changed: ", selectedRowKeys, selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    sendGoods(obj) {
      this.infoVisible = true;
      this.currentSendGoods = obj;
      this.sendNumber = "";
      this.logistics_company = "";
      this.sendData = JSON.parse(JSON.stringify(obj.sales_goods_items));
      this.sendData.forEach((el) => {
        this.$set(el, "sendQuantity", el.quantity);
      });
      console.log(obj, "obj");
    },
    handleOkInfo() {
      console.log(this.sendData, "send");

      if (!this.logistics_company) {
        this.$message.warn("请选择物流公司");
        return;
      }
      if (!this.sendNumber) {
        this.$message.warn("请输入物流编号");
        return;
      }

      if (this.selectedRowKeys.length === 0) {
        this.$message.warn("请勾选发货数据");
        return;
      }

      this.$axios
        .post("/sales_logistics/", {
          sales_order: this.currentSendGoods.id,
          waybill_id: this.sendNumber,
          logistics_company: this.logistics_company,
          logistics_goods_items: this.selectedRows.map((el) => {
            return {
              sales_goods: el.id,
              quantity: el.sendQuantity,
            };
          }),
        })
        .then(() => {
          this.$message.success("操作成功");
          this.infoVisible = false;
          this.initTable();
        });
    },
    onChangeTab(val) {
      if (val === -1) {
        this.status = "";
      } else {
        this.status = val;
      }
      this.initTable();
    },
    openDetail(obj) {
      this.$router.push({
        name: "orderDetail",
        query: {
          id: obj.id,
        },
      });
    },
    handleOk() {
      this.$axios
        .post(`/sales_orders/${this.current.id}/set_remark/`, {
          remark: this.remark,
        })
        .then(() => {
          this.visible = false;
          this.$message.success("操作成功");
          this.initTable();
        });
    },
    openRemark(obj) {
      this.current = obj;
      console.log(obj, "obj");
      this.visible = true;
      this.remark = "";
    },
    handleSearch() {
      console.log(this.searchDate, "searchDate", this.propStatus);
      this.initTable();
    },
    initTable() {
      // member_openid

      let params = { ...this.params };

      this.$axios
        .get("/sales_orders/", {
          params: params,
        })
        .then((res) => {
          this.dataSource = res.results || [];
          this.pagination.total = res.count || 0;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.btns {
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  > span {
    margin-left: 20px;
    color: #549ff8;
    cursor: pointer;
  }
}
.search__price {
  display: flex;
  align-items: center;
  > span {
    margin: 0 10px;
  }
}
</style>